import React from "react";
import styles from "./event.module.sass";
import getSkin from "./skin.js";
import { css } from "aphrodite/no-important";
import applyWrappers from "wrappers/ComponentWrapper";
import _ from "lodash";
import ActionList from "components/sprint/execution/actionList";
import { checkIfPresent } from "util/utilFunctions";
import { getUserMetricsForMetricsKey } from "util/utilFunctions";
import Skill from "svgComponents/skill";
import Morale from "svgComponents/morale";
import CustSat from "svgComponents/custSat";
import IncrementArrow from "svgComponents/incrementArrow";
import DecrementArrow from "svgComponents/decrementArrow";
import FilledButton from "commonComponents/buttons/filledButton";
import updateRoute from "util/webAppRoutes/updateRoute";

const Event = props => {
	const myStyles = getSkin(props.skinGuide);
	const { userEventsList, eventsToBeShownCount } = props.userEvents;

	const eventDetails =
		userEventsList[userEventsList.length - eventsToBeShownCount];

	if (!eventDetails) {
		return (
			<div
				className={css(myStyles.container, myStyles.responseMessage)}
				styleName="event-actions-container"
			>
				No new event
			</div>
		);
	}

	const getGlobalMetricsValue = (key, defaultValue = 0, defaultDiff = 0) => {
		const individualUserMetrics = getUserMetricsForMetricsKey(
			props.metrics.metricsList,
			props.userMetrics.userMetricsList,
			key
		);

		let value = defaultValue;
		let diff = defaultDiff;
		if (individualUserMetrics) {
			value = individualUserMetrics.value;
			diff = individualUserMetrics.diff;
		}

		return { value, diff };
	};

	const getMetricsvalue = (key, defaultValue = 0, defaultDiff = 0) => {
		const individualUserMetrics = getUserMetricsForMetricsKey(
			props.metrics.metricsList,
			eventDetails.userMetrics,
			key
		);

		let value = defaultValue;
		let diff = defaultDiff;

		if (individualUserMetrics) {
			value = individualUserMetrics.value;
			diff = individualUserMetrics.diff;
		}

		return { value, diff };
	};

	const getTagBGClass = changeType => {
		switch (changeType) {
			case "positive":
				return "greenTag";

			case "negative":
				return "redTag";

			default:
				return "greenTag";
		}
	};

	const renderArrow = changeType => {
		switch (changeType) {
			case "positive":
				return (
					<div styleName="arrow-container">
						<IncrementArrow />
					</div>
				);

			case "negative":
				return (
					<div styleName="arrow-container">
						<DecrementArrow />
					</div>
				);

			default:
				return null;
		}
	};

	const renderTag = (changeValue, changeType, metricType) => {
		if (changeType == "neutral") {
			return null;
		}
		const tagBGClass = getTagBGClass(changeType);
		let changePercent = changeValue;
		const title = props.getLabel(metricType);
		if (
			title === "Throughput" ||
			title === "Way of Working" ||
			title === "Business Focus"
		) {
			changePercent += "%";
		}

		return (
			<div
				className={css(myStyles.metricChange, myStyles[tagBGClass])}
				styleName="metrics-change"
			>
				{renderArrow(changeType)}
				<span>{changePercent}</span>
			</div>
		);
	};

	const getMetricImage = (metricType, color) => {
		switch (props.getLabel(metricType)) {
			case "Way of Working":
				return <Skill color={color} />;
			case "Business Focus":
				return <Morale color={color} />;
			case "Throughput":
				return <CustSat color={color} />;
			default:
				return;
		}
	};

	const renderGameMetrics = (title, value, changeValue, changeType) => {
		return (
			<div
				styleName="metric-container"
				className={css(myStyles.metricContainer)}
			>
				<div
					styleName="metric-content"
					className={css(myStyles.metricContent)}
				>
					<div styleName="metric-image-container">
						<div styleName="metric-image">
							{getMetricImage(title, "black")}
						</div>
					</div>
					<div styleName="metric-name-value-container">
						<div styleName="metric-name-desc-container">
							<div
								styleName="metric-name"
								className={css(myStyles.metricName)}
							>
								{props.getLabel(title)}
							</div>
						</div>
						<div
							styleName="metric-value"
							className={css(myStyles.metricValue)}
						>
							{props.getLabel(value)}
						</div>
					</div>
					{renderTag(changeValue, changeType, title)}
				</div>
			</div>
		);
	};

	const checkAndCallEndSprint = props => {
		const currentSprintNumber = props.userState.currentSprintNumber;
		props.endSprint(
			{
				sprintId: currentSprintNumber
			},
			() => {
				updateRoute({
					route: `/execution/sprint/${currentSprintNumber}/report`
				});
			}
		);
	};

	const redirectToSimReport = props => {
		const currentSprintNumber = props.userState.currentSprintNumber || 1;
		if (
			props.userState.currentSprintState == null ||
			props.userState.isGameCompleted
		) {
			updateRoute({
				route: `/execution/sprint/${currentSprintNumber}/report`
			});
		}
	};

	const closeEvent = () => {
		if (props.tutorial.isRunning) {
			props.endTutorial();
		}
		const updatedEventsToBeShownCount = eventsToBeShownCount - 1;

		if (updatedEventsToBeShownCount === 0) {
			if (props.uiState.showReplanSprintPopup) {
				props.setUiState({
					showOverlay: true,
					highlightDesktopHeader: true,
					highlightMetricsHeader: false,
					overlayComponentType: "CENTER",
					overlayComponent: "REPLAN_SPRINT_HELP_POPUP"
				});
			} else {
				props.setUiState({
					showOverlay: false,
					overlayComponentType: "",
					highlightDesktopHeader: false,
					highlightMetricsHeader: false,
					overlayComponent: ""
				});
			}
		}

		props.updateEventsToBeShownCount(updatedEventsToBeShownCount);
		if (updatedEventsToBeShownCount == 0) {
			if (
				props.userState.currentSprintDay > 15 &&
				props.userState.currentSprintState !== 4
			) {
				checkAndCallEndSprint(props);
			} else if (props.userState.isGameCompleted) {
				redirectToSimReport(props);
			}
		}else {
			props.setUiState({
				highlightDesktopHeader: true,
				highlightMetricsHeader: false,
				overlayComponentType: "CENTER",
				overlayComponent: "EVENT_LOADER"
			});
		}
	};

	const interactiveEvent =
		checkIfPresent(eventDetails.actionsList) &&
		eventDetails.actionsList.length > 0
			? true
			: false;

	let eventActionsList = [];
	let headerClassName = css(myStyles.headerInteractive, myStyles.header);

	if (interactiveEvent) {
		const eventActionsArray = eventDetails.actionsList;
		const eventActionsObj = _.keyBy(eventActionsArray, "actionId");
		eventActionsList = props.actions.actionsList.map(eachAction => {
			if (eventActionsObj[eachAction.id]) {
				return {
					...eachAction,
					route: eventActionsObj[eachAction.id].route,
					cost: eventActionsObj[eachAction.id].cost,
					effort: eventActionsObj[eachAction.id].effort,
					responseImageKey:
						eventActionsObj[eachAction.id].responseImageKey
				};
			}
		});
		eventActionsList = eventActionsList.filter(eventAction => eventAction);
	} else {
		if (checkIfPresent(eventDetails.direction)) {
			const direction = eventDetails.direction;
			if (direction === 1) {
				headerClassName = css(myStyles.headerPositive, myStyles.header);
			} else if (direction === -1) {
				headerClassName = css(myStyles.headerNegative, myStyles.header);
			} else
				headerClassName = css(myStyles.headerNeutral, myStyles.header);
		} else {
			let overallImpact = 0;
			eventDetails.userMetrics.map(userMetric => {
				overallImpact += userMetric.diff;
				return 1;
			});
			if (overallImpact > 0) {
				headerClassName = css(myStyles.headerPositive, myStyles.header);
			} else if (overallImpact < 0) {
				headerClassName = css(myStyles.headerNegative, myStyles.header);
			} else
				headerClassName = css(myStyles.headerNeutral, myStyles.header);
		}
	}

	const wofwDefaultValue = getGlobalMetricsValue("WAYOFWORKING").value;
	const wofwMetricsValue = getMetricsvalue("WAYOFWORKING", wofwDefaultValue);
	const wofw = wofwMetricsValue.value;
	const wofwDiff = wofwMetricsValue.diff;

	const bfDefaultValue = getGlobalMetricsValue("BUSINESSFOCUS").value;
	const bfMetricsValue = getMetricsvalue("BUSINESSFOCUS", bfDefaultValue);
	const bf = bfMetricsValue.value;
	const bfDiff = bfMetricsValue.diff;

	const progressDefaultValue = getGlobalMetricsValue("PROGRESS").value;
	const progressMetricsValue = getMetricsvalue(
		"PROGRESS",
		progressDefaultValue
	);
	const progress = progressMetricsValue.value;
	const progressDiff = progressMetricsValue.diff;
	return (
		<div styleName="event-actions-container">
			<div styleName="event-child" id="event-actions-container"></div>
			<div styleName="event-actions-header" className={headerClassName}>
				{props.getLabel(eventDetails.name)}
			</div>
			<div
				styleName="event-actions-content"
				className={css(myStyles.eventActionsContent)}
			>
				<div styleName="event-image-container">
					<img
						styleName="image"
						src={props.getImage("TIGHTER_ENVIRONMENTAL")}
					/>
				</div>
				<div
					styleName="event-text"
					style={
						!interactiveEvent
							? { width: "61.347vw", marginTop: "1vh" }
							: null
					}
				>
					<div className={css(myStyles.eventDescription)}>
						{props.getLabel(eventDetails.description)}
					</div>
					{!interactiveEvent ? (
						<div styleName="metrics-container">
							{renderGameMetrics(
								"label_cust",
								`${progress}%`,
								`${
									progressDiff >= 0
										? progressDiff
										: -progressDiff
								}`,
								progressDiff > 0
									? "positive"
									: progressDiff < 0
									? "negative"
									: "neutral",
								false
							)}
							{renderGameMetrics(
								"label_skill",
								`${wofw}%`,
								`${wofwDiff >= 0 ? wofwDiff : -wofwDiff}`,
								wofwDiff > 0
									? "positive"
									: wofwDiff < 0
									? "negative"
									: "neutral",
								true
							)}
							{renderGameMetrics(
								"label_morale",
								`${bf}%`,
								`${bfDiff >= 0 ? bfDiff : -bfDiff}`,
								bfDiff > 0
									? "positive"
									: bfDiff < 0
									? "negative"
									: "neutral",
								true
							)}
						</div>
					) : null}
				</div>
				{interactiveEvent ? (
					<div styleName="event-action-list">
						<div className={css(myStyles.actionChoice)}>
							{props.getLabel("label_choose_action")}
						</div>
						<ActionList
							{...props}
							actionsList={eventActionsList}
							isActionsFromEventPopup={true}
							isActionsFromStoryPopup={false}
							updateEventsToBeShownCount={props.updateEventsToBeShownCount}
							updatedEventsToBeShownCount={eventsToBeShownCount-1}
						/>
					</div>
				) : null}
				{!interactiveEvent ? (
					<div styleName="close-container">
						<FilledButton
							textLabel={props.getLabel("label_continue")}
							clickFunction={closeEvent}
							customStyle={{
								width: "50px"
							}}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default applyWrappers(Event, styles);
