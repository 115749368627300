import React from 'react';
import styles from './metric.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import UpArrowReport from 'svgComponents/upArrowReport';
import DownArrowReport from 'svgComponents/downArrowReport';
import InfoComponent from 'commonComponents/infoComponent';
import { checkIfPresent, roundValue } from 'util/utilFunctions';
import Calendar from '../../../../../svgComponents/calendar/Calendar.jsx';


const Metric = (props) => {
	// const {mvp, bf,wofw,progress,budget, quality} = props.userMetrics;
	const myStyles = getSkin(props.skinGuide);
	const { userState, metrics, userMetrics, selected, getLabel } = props;

	const valueChange = (changeInMetricValue) => {
		if (changeInMetricValue === 0) return myStyles.bChange;
		if(changeInMetricValue>0) return myStyles.bChangePos
		return myStyles.bChangeNeg
	}

	const big = (name, met, defaultMetrics) => {
		// const change = getChange(met)
		//  console.log(met);
		 let mvp = `0%`;
		 if (met.currentSprintValue) {
			 mvp = `${roundValue(met.currentSprintValue,0)}%`;
		 }
		const maxWidht = 380;
		const nowWidth = (met.currentSprintValue / 100) * maxWidht;
		const arrow = met.changeInMetricValue < 0
			? <DownArrowReport svgColor={"#D63228"} />
			: <UpArrowReport svgColor={met.changeInMetricValue == 0 ? "rgba(0, 0, 0, 0.4)" : "#36B496"} />;
		const desc = defaultMetrics.find((m) => m.metricsId == met.metricsId).description;
		return (
			<div styleName="big" className={css(myStyles.big)}>
				{/* <div styleName="name-container">
					<div styleName="name">
						{props.getLabel('label_mvp_alignment')}
					</div>
					<InfoComponent
						tooltipText={props.getLabel(desc)}
						alignTooltipKey="RIGHT"
					/>
				</div>
				<div styleName="completion">
					{roundValue(met.currentSprintValue, 1)}%
				</div>
				<div styleName="b-change">
					<div styleName="arrow">
						{arrow}
					</div>
					{`${roundValue(met.changeInMetricValue, 1)} ${getLabel("label_report_in_sprint")} ${selected}`}
				</div>
				<div styleName="graph-box">
					<div styleName="graph"></div>
					<div styleName="graph-overlay" style={{ width: nowWidth }}></div>
				</div> */}
				<div id="sim-mvp" styleName="sim-mvp-container" className={`metric-tutorial-mvp  ${css(myStyles.mvpContainer)}`} >
					<div styleName="sim-mvp-content">
						<div styleName="sim-mvp-arrow-container">
							
							<div styleName="sim-mvp-value" className={css(myStyles.mvpValue)}>
								{`${mvp}`}
							</div>
						</div>
						<div styleName="sim-mvp-details">
							<div styleName="sim-mvp-top">
								<div styleName="sim-mvp-text" className={css(myStyles.mvpText)}>
									{props.getLabel('label_mvp_alignment')}
								</div>
								<InfoComponent
									tooltipText={props.getLabel('label_metric_desc_pc_fit')}
									alignTooltipKey="RIGHT"
									svgColor = "#333333"
									// svgColor={hexToRgbA(skin.white, 0.8)}
								/>
								{roundValue(met.changeInMetricValue, 0) !== 0 &&
								<div styleName="b-change" className={css(valueChange(met.changeInMetricValue))}>
									<div styleName="arrow">
										{arrow}
									</div>
									{`${roundValue(met.changeInMetricValue, 0)}${getLabel("label_report_in_sprint")} ${selected}`}
								</div>}
							</div>
							<div styleName="sim-mvp-bottom">
								<div styleName="sim-mvp-progress-bar-container" className={css(myStyles.mvpProgressBarContainer)}>
									<div
										style={{
											width: mvp
										}}
										styleName="sim-mvp-progress-bar"
										className={css(myStyles.mvpProgressBar)}
									></div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		)
	}

	const getClassForMetric = (currentSprintValue, valueColorChangeFlag) => {
		if(!valueColorChangeFlag) {
			return "completion";
		}
		//console.log(currentSprintValue)
		if(currentSprintValue < 50) {
			return "completion value-red";
		} else if (currentSprintValue >= 50) {
			return "completion value-green";
		}
		return "completion";
	}


	const small = (name, met, metricsBottom, postText, ceil, defaultMetrics, valueColorChangeFlag, divId) => {
		// const change = getChange(met)
		const arrow = met.changeInMetricValue <= 0
			? <DownArrowReport svgColor={"#D11021"} />
			: <UpArrowReport svgColor={met.changeInMetricValue == 0 ? "rgba(0, 0, 0, 0.4)" : "#0B7F3D"} />;
		const metric = defaultMetrics.find((m) => m.metricsId == met.metricsId);
		let description = metric.description;
		if(metric.key == "wofw"){
			description = "label_metric_wofw_tooltip_desc";
		}
		else if(metric.key == "bf"){
			description = "label_metric_bf_tooltip_desc";
		}
		else if(metric.key == "progress"){
			description = "label_metric_progress_tooltip_desc";
		}
		return (
			<div styleName={metricsBottom ? "small mb" : "small"}
			style={valueColorChangeFlag ? met.currentSprintValue < 50 ? {border: "0.14vw solid #D11021", background: "#FFDEE6"} : {border: "0.14vw solid #0B7F3D", background: "#E7FBEA"} : null}>
				<div styleName="small-child" id={divId}></div>
				<div styleName="name-container">
				<div styleName="name" className={css(myStyles.name)}>
						{name}
					</div>
					<InfoComponent
						tooltipText={props.getLabel(description)}
						alignTooltipKey="RIGHT"
						svgColor = "#333333"
					/>
				</div>
				{
					checkIfPresent(met.currentSprintValue)
						? <div styleName={getClassForMetric(met.currentSprintValue, valueColorChangeFlag)} className={css(myStyles.completion)}> 
							{met.currentSprintValue && ceil ? Math.ceil((met.currentSprintValue)) : roundValue(met.currentSprintValue, 0)}{postText}
						</div>
						: null
				}
				{
					metricsBottom && Math.round(met.changeInMetricValue)!==0
						? <div styleName="b-change" className={css(valueChange(met.changeInMetricValue))}>
							<div styleName="arrow">
								{arrow}
							</div>
							<div> 
								{`${Math.abs(Math.round(met.changeInMetricValue))}${getLabel("label_report_in_sprint")} ${selected}`}
							</div>
						</div>
						: null
							// (
							// 	<div styleName="b-change-small">
							// 		<div styleName="arrow-small">
							// 			{arrow}
							// 		</div>
							// 		{Math.abs(Math.floor(met.changeInMetricValue))}
							// 	</div>
							// )
				}
			</div>
		)
	}


	const resource = (name, label, pre, score, post, helpText,icon) => {
		return (
			<div styleName="re-box">
				<div styleName="re-box-image">
					<img width="100%" height="100%" src={props.getImage(icon)}/>
				</div>
				<div>
					<div styleName="re-name-container">
						<div styleName="re-name" className={css(myStyles.reName)}>
							{props.getLabel(label)}   
						</div>
						<InfoComponent
							tooltipText={props.getLabel(helpText)}
							alignTooltipKey="RIGHT"
							svgColor = "#333333"
						/>
					</div>
					<div styleName="re-completion" className={css(myStyles.reCompletion)}>
						{pre}{score}{post}
					</div>
				</div>
			</div>
		)
	}
	const getMetricsvalue = (key, defaultValue = 0) => {
		const individualUserMetrics = getUserMetricsForMetricsKey(
			metrics.metricsList,
			userMetrics,
			key
		);

		if (individualUserMetrics) {
			return individualUserMetrics;
		}

		return {};
	}

	const bf = getMetricsvalue('BUSINESSFOCUS');
	const progress = getMetricsvalue('PROGRESS')
	const budget = getMetricsvalue('BUDGET')
	const wofw = getMetricsvalue('WAYOFWORKING')
	const mvp = getMetricsvalue('MVP')
	const velocity = getMetricsvalue('VELOCITY')
	// const accuracy = getMetricsvalue('ACCURACY')
	const efficiency = getMetricsvalue('EFFICIENCY')
	let daysRemaining = userState.totalDays - userState.currentDay;
	daysRemaining = daysRemaining < 0 ? 0 : daysRemaining;
	let daysRemainingAtSprint = props.sprintReport.firstSprintDay + userState.totalDaysInSprint >= userState.totalDays
		? 0
		: userState.totalDays - (props.sprintReport.firstSprintDay + userState.totalDaysInSprint - 1);
	return (
		<div styleName="metrics-resources">
			<div>
				<div 
					styleName="met-name" 
					className={props.fromPopup?css(myStyles.metNamePopup):css(myStyles.metName)}
					style={{width: "40vw"}}
				>{getLabel("label_report_metrics")}</div>
				<div styleName="big-box">
					{big("Product-customer fit", mvp, metrics.metricsList)}
					<div styleName="small-boxes" style={{marginBottom:0}}>
						{small("Velocity", velocity, false, "", true, metrics.metricsList, false, "metric-velocity")}
						{/* {small("Accuracy", accuracy, false, "%", false, metrics.metricsList, true, "metric-accuracy")} */}
						{small("Efficiency", efficiency, false, "%", false, metrics.metricsList, true, "metric-efficiency")}
					</div>
				</div>
				<div styleName="small-boxes">
					{small("Throughput", progress, true, "%", false, metrics.metricsList, false, "metric-cust")}
					{small("Way of working", wofw, true, "%", false, metrics.metricsList, false, "metric-skill")}
					{small("Business Focus", bf, true, "%", false, metrics.metricsList, false, "metric-morale")}
				</div>
			</div>
			<div>
				<div 
					styleName="met-name" 
					className={props.fromPopup?css(myStyles.metNamePopup):css(myStyles.metName)}
					style={{width: "13.177vw", marginBottom: "1vh"}}
				>{getLabel("label_resources")}</div>
				<div styleName="small-boxes small-boxes-bottom">
					{resource("DAYS REMAINING", "label_days_remaining_report", "", daysRemainingAtSprint, "", 'label_report_daysremaining_helptext','IMG_RE_CAL')}
					{resource("Budget remaining", "label_budget_remaining", "₣", budget.currentSprintValue, "", 'label_report_budget_helptext','IMG_RE_BAG')}
				</div>
			</div>
		</div>
	)
}

export default applyWrappers(Metric, styles);